import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { SaveNew } from 'src/app/services/dto/news-model';

export interface SpecialAppsState {
  announcements: SaveNew[];
  faqs: any[];
}

export function createInitialState(): SpecialAppsState {
  return {
    announcements: [],
    faqs: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'specialApps',resettable: true })
export class SpecialAppsStore extends Store<SpecialAppsState> {
  constructor() {
    super(createInitialState());
  }
}
