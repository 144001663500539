import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalService } from 'src/app/services/global.service';
import { OrwiStoreService } from 'src/app/store/orwi/orwi-store.service';

@Component({
  selector: 'app-store-qr-code',
  templateUrl: './store-qr-code.component.html',
  styleUrls: ['./store-qr-code.component.scss'],
})
export class StoreQrCodeComponent implements OnInit {
  constructor(
    private orwiStoreService: OrwiStoreService,
    private modalController: ModalController,
    private glb: GlobalService
  ) {}
  storeId : string;
  img: string;

  link: string;

  ngOnInit() {
    this.orwiStoreService.getMenuQr(this.storeId).then(
      (o: any) => {
        this.img = o.base64;
        this.link = o.link;
      },
      (err) => {
        this.glb.toast('', 'QR Code could not be found!', 'bottom', 'danger');
        setTimeout(() => {
          this.close();
        }, 500);
      }
    );
  }

  close() {
    this.modalController.dismiss();
  }
}
