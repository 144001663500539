import { Injectable } from '@angular/core';
import { OrwiService } from 'src/app/services/orwi.service';
import { requestList } from 'src/app/services/request-list';
import { FoliosStore } from './folios.store';
import { DeliveryFolio, Folio } from '../../services/dto/folio-model';
import { arrayUpsert } from '@datorama/akita';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class FoliosService {
  constructor(
    private orwiService: OrwiService,
    private foliosStore: FoliosStore,
    private orwiFolioStore: FoliosStore
  ) {}

  getOrwiFoliosByDate(
    param: FoliosDate,
    skip: number,
    limit: number
  ): Promise<Folio[]> {
    // debugger
    let today = moment().startOf('day').toDate();
    let endToday = moment().endOf('day').toDate();
    let data = {
      startDate: param.today ? today.toISOString() : param.startDate,
      endDate: param.today ? endToday.toISOString() : param.endDate,
    };
    let storeId = localStorage.getItem('activeStore');
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(requestList.folio.getOrwiFoliosByDate, {
          id: storeId,
          startDate: data.startDate,
          endDate: data.endDate,
          skip: skip,
          limit: limit,
        })
        .then((o: any) => {
          if (o.response) {
            let folios = o.response.filter((elm: Folio) => elm.storeId == storeId);
            this.orwiFolioStore.update({
              orwiFolios: folios,
            });
            return resolve(folios);
          }

          return reject(o.error);
        });
    });
  }

  getFolioReport(startDate : string,endDate : string) {
    return new Promise((resolve, reject) => {
      return this.orwiService.serviceRequestPromise(requestList.folio.getReportFolio,{startDate : startDate,endDate:endDate}).then((o:any) =>{
        //console.log('GetFolioReport',o)
        if (o){ 
          resolve(o)
        }
        return reject(o.error);
      })
    })
  }

  getFoliosByDate(
    param: FoliosDate,
    skip: number = 0,
    limit: number = 100
  ): Promise<Folio[]> {
    // debugger
    let today = moment().startOf('day').toDate();
    let endToday = moment().endOf('day').toDate();

    let data = {
      startDate: param.today ? today.toISOString() : param.startDate,
      endDate: param.today ? endToday.toISOString() : param.endDate,
    };
    console.log(data.startDate, data.endDate);
    let storeId = localStorage.getItem('activeStore');
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(requestList.folio.getFoliosByDate, {
          id: storeId,
          startDate: data.startDate,
          endDate: data.endDate,
          skip: skip,
          limit: limit,
        })
        .then((o: any) => {
          if (o.response) {
            let folios = o.response.filter((elm) => elm.storeId == storeId);
            this.foliosStore.update({
              folios: folios,
            });
            return resolve(folios);
          }

          return reject(o.error);
        });
    });
  }

  getDeliveryFoliosOnOwnedStores(param: FoliosDate): Promise<any[]> {
    let today = new Date();
    let data = {
      startDate: param.today
        ? new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            9,
            0,
            0
          )
        : param.startDate,
      endDate: param.today
        ? new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            23,
            59,
            59
          )
        : param.endDate,
    };
    let storeId = localStorage.getItem('activeStore');
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(
          requestList.folio.getDeliveryFoliosOnOwnedStores,
          data
        )
        .then((o: any) => {
          if (o.response) {
            let deliveryFolios = o.response.filter(
              (elm) => elm.storeId == storeId
            );
            this.foliosStore.update({
              deliveryFolios: deliveryFolios,
            });
            return resolve(deliveryFolios);
          }

          return reject(o.error);
        });
    });
  }



  updateDeliveryStatus({ id, status }) {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(requestList.folio.updateFolioDeliveryStatus, {
          id,
          status,
        })
        .then((o: any) => {
          if (o.response) {
            this.foliosStore.update(({ deliveryFolios }) => ({
              deliveryFolios: arrayUpsert(deliveryFolios, id, { status }),
            }));
            return resolve(o.response);
          }

          return reject(o.error);
        });
    });
  }
}

interface FoliosDate {
  startDate?: string;
  endDate?: string;
  today?: boolean;
}