import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Printer } from 'src/app/services/dto/definitions-model';

export interface PrinterState {
  printers: Printer[];
}

export function createInitialState(): PrinterState {
  return {
    printers: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'printer',resettable: true })
export class PrinterStore extends Store<PrinterState> {
  constructor() {
    super(createInitialState());
  }
}
