import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { DeleteReason, Discount, EcrDepartment, TaxRate } from 'src/app/services/dto/definitions-model';
// import { Definitions } from 'src/app/services/dto/definitions-model';

export interface DefinitionsState {
  gift: DeleteReason[];
  taxRate: TaxRate[];
  ecrDepartment: EcrDepartment[];
  discount: Discount[];
  reason: DeleteReason[];
}

export function createInitialState(): DefinitionsState {
  return {
    gift: [],
    taxRate: [],
    ecrDepartment: [],
    discount: [],
    reason: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'definitions',resettable: true })
export class DefinitionsStore extends Store<DefinitionsState> {
  constructor() {
    super(createInitialState());
  }
}
