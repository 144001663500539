export interface UserSegment {
  id : string
  name  : string
  description :string
  detailText :string
  sequence : number 
  segmentRule: UserSegmentRuleTypes 
  targetSpendMin : number 
  targetSpendMax : number 
  //benefitType: "point" | "discount-basket" = "point"
  benefitAmount : number 
  image : string
  deleted :boolean  
  deleteDate: Date | undefined
}

export enum UserSegmentRuleTypes {
  none = "",
  last3MonthBasketTotal = "last-3-month-basket-total",
  identityNoCityControl = "identity-no-city-control"
}

export type benefitType = 'point' | 'discount-basket';


export const segmentRuleTypes = ["last-3-month-basket-total", "identity-no-city-control"]
export const benefitTypes = ["point", "discount-basket"]


export class lookUpData {
  value : string
  name : string;
}
