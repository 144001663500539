import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MenuState, MenuStore } from './menu.store';

@Injectable({ providedIn: 'root' })
export class MenuQuery extends Query<MenuState> {
  products$ = this.select('products');
  groups$ = this.select('groups');
  groupsImages$ = this.select('groupImages');
  activeProducts$ = this.select('activeProducts');
  activeGroup$ = this.select('selectedGroup');
  subGroups$ = this.select('subGroups');
  selectedProduct$ = this.select('selectedProduct');
  allModifiers$ = this.select('allModifiers');
  groupModifiers$ = this.allModifiers$.pipe(
    map((data) => data.filter((dt) => dt.modif[0]?.type == 'group'))
  );
  productModifiers$ = this.allModifiers$.pipe(
    map((data) => data.filter((dt) => dt.modif[0]?.type == 'product'))
  );
  customModifiers$ = this.allModifiers$.pipe(
    map((data) => data.filter((dt) => dt.modif[0]?.type == 'custom'))
  );
  allAdvices$ = this.select('allAdvices');
  groupAdvices$ = this.allAdvices$.pipe(map((data) => data));

  attributes$ = this.select('attributes').pipe(shareReplay());
  productAttributes$ = this.attributes$.pipe(
    map((data) => data.filter((elm) => elm.type == 'product'))
  );

  groupsWithImage$ = combineLatest([this.groups$, this.groupsImages$]).pipe(
    map(([groups, images]) => {
      return groups.map((grp) => {
        let image = images.find((elm) => elm.itemId == grp.id);
        return {
          ...grp,
          base64: image?.base64,
        };
      });
    })
  );
  constructor(protected store: MenuStore) {
    super(store);
  }
}
