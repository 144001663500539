import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { PrinterState, PrinterStore } from './printer.store';

@Injectable({ providedIn: 'root' })
export class PrinterQuery extends Query<PrinterState> {
  printers$ = this.select('printers');

  constructor(protected store: PrinterStore) {
    super(store);
  }
}
