import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';
const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function phoneVerification(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let phoneVerify = false;
    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
        (control.value as string || '')
          .replace('(', '')
          .replace(')', '')
          .split(' ')
          .join(''),
        localStorage.getItem('activeCountryCode') || 'TR'
      );
      phoneVerify = !phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (error) {
      phoneVerify = true;
    }
    return phoneVerify ? { phoneInvalid: true } : null;
  };
}
