import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { DefinitionsState, DefinitionsStore } from './definitions.store';

@Injectable({ providedIn: 'root' })
export class DefinitionsQuery extends Query<DefinitionsState> {
  gift$ = this.select('reason').pipe(
    map((data) => data.filter((elm) => elm.types == 'gift'))
  );
  taxRate$ = this.select('taxRate');
  ecrDepartment$ = this.select('ecrDepartment');
  discount$ = this.select('discount');
  deleteProduct$ = this.select('reason').pipe(
    map((data) => data.filter((elm) => elm.types == 'product'))
  );;

  constructor(protected store: DefinitionsStore) {
    super(store);
  }
}
