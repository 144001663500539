import { Injectable } from '@angular/core';
import { arrayRemove, arrayUpsert } from '@datorama/akita';
import {
  definitionType,
  definitionUrlType,
} from 'src/app/services/dto/definitions-model';
import { OrwiService } from 'src/app/services/orwi.service';
import { requestList } from 'src/app/services/request-list';
import { DefinitionsStore } from './definitions.store';

@Injectable({
  providedIn: 'root',
})
export class DefinitionsService {
  constructor(
    public orwiService: OrwiService,
    private definitionsStore: DefinitionsStore
  ) {}

  async initializeDefinitions() {
    await this.getDefinitions('reason', 'deleteReason');
    await this.getDefinitions('discount', 'discountReason');
    await this.getDefinitions('taxRate', 'taxRate');
    await this.getDefinitions('ecrDepartment', 'ecrDepartment');
    // await this.getDefinitions('gift','giftReason');
  }

  getDefinitions<T>(type: definitionType, url: definitionUrlType): Promise<T> {
    let storeId = localStorage.getItem('activeStore');
    return new Promise((resolve, reject) => {
      // return resolve([{id:1}] as any);
      return this.orwiService
        .serviceRequestPromise(requestList.pos[`${url}`][`get${url}`], {
          id: storeId,
        })
        .then(
          (o: any) => {
            if (o.response) {
              let result = Object.assign(o?.response);

              if (result) return resolve(result);
            }
            return reject(o?.error?.code);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  saveDefinitions(
    definition: any,
    type: definitionType,
    url: definitionUrlType
  ): Promise<any> {
    let storeId = localStorage.getItem('activeStore');

    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(requestList.pos[`${url}`][`save${url}`], {
          ...definition,
          storeId: storeId,
        })
        .then((o: any) => {
          if (o.response) {
            this.definitionsStore.update((def) => ({
              [type]: arrayUpsert(def[`${type}`], o.response.id, {
                ...o.response,
                storeId: storeId,
              }),
            }));
            return resolve(o.response);
          }

          return reject(o?.error?.code);
        });
    });
  }

  deleteDefinitions(
    definitionId: any,
    type: definitionType,
    url: definitionUrlType
  ): Promise<any> {
    let storeId = localStorage.getItem('activeStore');

    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(requestList.pos[`${url}`][`delete${url}`], {
          id: definitionId,
          storeId: storeId,
        })
        .then((o: any) => {
          if (o.response) {
            this.definitionsStore.update((def) => ({
              [type]: arrayRemove(def[`${type}`], definitionId),
            }));
            return resolve(o.response);
          }

          return reject(o?.error?.code);
        });
    });
  }
}
