import { Injectable } from '@angular/core';
import { arrayRemove, arrayUpsert } from '@datorama/akita';
import { SaveNew } from 'src/app/services/dto/news-model';
import { OrwiService } from 'src/app/services/orwi.service';
import { requestList } from 'src/app/services/request-list';
import { SpecialAppsStore } from './special-apps.store';

@Injectable({
  providedIn: 'root',
})
export class SpecialAppsService {
  constructor(
    public orwiService: OrwiService,
    private specialAppsStore: SpecialAppsStore
  ) {}

  getAllNews(): Promise<SaveNew[]> {
    const storeId = localStorage.getItem('activeStore');
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(requestList.feed.getNewsItem, {
          id: storeId,
          specialApps: true,
        })
        .then((o: any) => {
          if (o.response) {
            // this.specialAppsStore.update({
            //   announcements: o.response,
            // });
            return resolve(o.response);
          }

          return reject(o?.error?.code);
        });
    });
  }

  getNewsImage(newId: string, size: 'small' | 'large' = 'large') {
    let store = localStorage.getItem('activeStore');
    // debugger
   console.log(store , newId )
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise('/api/image/imageNews', {
          id: store,
          newId        
        })
        .then(
          (o: any) => {
            if (o.response) {
              let result = Object.assign(o.response);
              return resolve(result);
            }
            return reject(o?.error?.code);          
          },
          (e) => {
            console.log('news image load error', e);
            reject(false);
          }
        );
    });
  }


  saveNew(saveNew: SaveNew) {
    const storeId = localStorage.getItem('activeStore');

    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(requestList.feed.saveNewsItem, {
          ...saveNew,
          storeId,
          specialApps: true,
        })
        .then((o: any) => {
          if (o.response) {
            this.specialAppsStore.update(({ announcements }) => ({
              announcements: arrayUpsert(announcements, saveNew.id, o.response),
            }));
            return resolve(o.response);
          }

          return reject(o?.error?.code);
        });
    });
  }

  deleteNew(id: string) {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise('/api/' + 'feed' + '/' + 'deleteNewsItem', {
          id: id,
          specialApps: true,
        })
        .then((o: any) => {
          if (o.response) {
            this.specialAppsStore.update(({ announcements }) => ({
              announcements: arrayRemove(announcements, id),
            }));
            return resolve(o.response);
          }

          return reject(o?.error?.code);
        });
    });
  }

  getAllFaqs(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(requestList.faq.getFAQList, {
          specialApps: true,
        })
        .then((o: any) => {
          if (o.response) {
            // this.specialAppsStore.update({
            //   faqs: o.response,
            // });
            return resolve(Object.assign(o).response);
          }
          return reject(o?.error?.code);
        });
    });
  }

  addFaq(faq: any) {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(requestList.faq.saveFAQItem, {
          ...faq,
          specialApps: true,
        })
        .then((o: any) => {
          if (o.response) {
            this.specialAppsStore.update(({ faqs }) => ({
              faqs: arrayUpsert(faqs, o.response.id, o.response),
            }));
            return resolve(Object.assign(o).response);
          }
          return reject(o?.error?.code);
        });
    });
  }

  deleteFaq(id: string) {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(requestList.faq.deleteFAQItem, {
          id,
          specialApps: true,
        })
        .then((o: any) => {
          if (o.response) {
            this.specialAppsStore.update(({ faqs }) => ({
              faqs: arrayRemove(faqs, id),
            }));
            return resolve(Object.assign(o).response);
          }
          return reject(o?.error?.code);
        });
    });
  }
}
