import { Injectable } from '@angular/core';
import { arrayRemove, arrayUpsert } from '@datorama/akita';
import { Printer } from 'src/app/services/dto/definitions-model';
import { OrwiService } from 'src/app/services/orwi.service';
import { requestList } from 'src/app/services/request-list';
import { PrinterStore } from './printer.store';

@Injectable({
  providedIn: 'root',
})
export class PrinterService {
  constructor(
    public orwiService: OrwiService,
    private printerStore: PrinterStore
  ) {}

  getPrinters(): Promise<Printer[]> {
    let storeId = localStorage.getItem('activeStore');

    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(requestList.pos.printer.getPrinters, {
          id: storeId,
        })
        .then(
          (o: any) => {
            if (o.response) {
              let result = Object.assign(o.response);

              if (result) return resolve(result);
            }
            return reject(o?.error?.code);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  savePrinter(printer: Printer): Promise<Printer> {
    let storeId = localStorage.getItem('activeStore');

    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(requestList.pos.printer.savePrinter, {
          ...printer,
          storeId: storeId,
        })
        .then((o: any) => {
          if (o.response) {
            this.printerStore.update(({ printers }) => ({
              printers: arrayUpsert(printers, printer.id, {
                ...printer,
                storeId: storeId,
              }),
            }));
            return resolve(o.response);
          }

          return reject(o?.error?.code);
        });
    });
  }

  deletePrinter(printer: Printer): Promise<Printer> {
    let storeId = localStorage.getItem('activeStore');

    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(requestList.pos.printer.deletePrinter, {
          id: printer.id,
          storeId: storeId,
        })
        .then((o: any) => {
          if (o.response) {
            this.printerStore.update(({ printers }) => ({
              printers: arrayRemove(printers, printer.id),
            }));
            return resolve(o.response);
          }

          return reject(o?.error?.code);
        });
    });
  }
}
";:"