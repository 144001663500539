import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest, concat } from 'rxjs';
import { mergeAll } from 'rxjs/operators';
import { FoliosState, FoliosStore } from './folios.store';


@Injectable({ providedIn: 'root' })
export class FoliosQuery extends Query<FoliosState> {   
    folios$ = this.select('folios');
    orwiFolios$ = this.select('orwiFolios');
    deliveryFolios$ = this.select('deliveryFolios');

    allFolios$ = concat(this.folios$,this.orwiFolios$)

    constructor(protected store: FoliosStore)  {
        super(store);
    }

}