import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { DeliveryFolio, Folio } from 'src/app/services/dto/folio-model';

export interface FoliosState {
  folios: Folio[];
  orwiFolios: Folio[],
  deliveryFolios: DeliveryFolio[];
}

export function createInitialState(): FoliosState {
  return {
    folios: [],
    orwiFolios: [],
    deliveryFolios: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'folios',resettable: true })
export class FoliosStore extends Store<FoliosState> {
  constructor() {
    super(createInitialState());
  }
}
