import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SpecialAppsState, SpecialAppsStore } from './special-apps.store';

@Injectable({ providedIn: 'root' })
export class SpecialAppsQuery extends Query<SpecialAppsState> {
  announcements$ = this.select('announcements');
  faqs$ = this.select('faqs');

  constructor(protected store: SpecialAppsStore) {
    super(store);
  }
}
